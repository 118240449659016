import React from 'react';
import Alert from './Alert'
class ContactForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            responseMessage:null,
            submitted:false,
            loading:false,
        }
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    submitForm(event){
        event.preventDefault();
        this.setState({responseMessage:null,loading:true})
        const form = event.target;
        const formData = new FormData(form);

        fetch('/',{
            method:"POST",
            headers:{ "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        }).then(()=>{
            form.reset();
            this.setState({loading:false,submitted:true});
        }).catch((error)=>{
            console.log(error)
            this.setState({loading:false,responseMessage:"There was a problem submitting this form."})

        });
        
    }
   
    handleChange(event,name,value){
        if(event) {
            this.setState({[event.target.name]:event.target.value})
        } else {
            this.setState({[name]:value})
        }
    }
    render(){
    
        return(
            <>
                {
                    this.state.submitted ?
                        <Alert type="success" message="Your request has been sent. Typical response time is 1-2 business days. Thank you!"/>
                    :  <form name="contact" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.submitForm}>
                      <input type="hidden" name="form-name" value="contact" />
                    <div className="input-group">
                        <label htmlFor="name">Name</label> 
                        <input type="text" name="name" required/> 
                    </div>   
                    <div className="input-group">
                        <label htmlFor="email">Email</label> 
                        <input type="email" name="email" required/> 
                    </div>   
                    <div className="input-group">
                        <label htmlFor="phone">Phone</label> 
                        <input type="tel" name="phone" /> 
                    </div>   
                    <div className="input-group">
                        <label htmlFor="address">Address</label> 
                        <input type="text" name="address" /> 
                    </div>
                    <div className="input-group">
                        <label htmlFor="city">City</label> 
                        <input type="text" name="city" /> 
                    </div>   
                    <div className="input-group">
                        <label htmlFor="state">State</label> 
                        <input type="text" name="state" /> 
                    </div>  
                    <div className="input-group">
                        <label htmlFor="zip">Zip</label> 
                        <input type="text" name="zip" /> 
                    </div>    
                    <div className="input-group">
                        <label htmlFor="propertyType">Property Type</label> 
                        <select name="propertyType">
                            <option value="">Choose a type</option>
                            <option value="residential">Residential</option>
                            <option value="commercial">Commercial</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="additionalInfo">Additional Info</label>    
                        <textarea name="additionalInfo" placeholder="Any additional information you can provide so we can better address your needs (optional)"/>
                    </div>  
                    {
                        this.state.responseMessage && <Alert type="error" message={this.state.responseMessage}/>
                    }
                    <div className="row center">
                        {
                            this.state.loading ? 
                            <span className="loading animated in-view infinite" data-animatequeue="pulse">Loading...</span>
                            :  <button type="submit" className="btn primary">Submit</button>
                        }
                    </div>
                    </form> 
                }
              
            </>
        )
    }
} 

export default ContactForm;