import React from 'react';
export default function Alert(props){
    
    return (
        <div className={`alert ${props.type}`} onClick={props.dismiss}>
            <span className="alert-message">{props.message}</span>
            {
                props.dismiss &&  <span className="dismiss"/>
            }
           
        </div>
    )
}