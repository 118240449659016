import React from 'react'
import Layout from '../components/Layout'
import {Location} from '@reach/router'
import Content from '../components/Content'
import contentImg from '../images/home-evaluation.jpg'
import ContactForm from '../components/ContactForm'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                        <h2>Start Protecting Your Home Today</h2>
                        <div className="row center">
                            <img src={contentImg} style={{width:'100%'}}/>
                        </div>
                        <h3>Provide us with the following information and we’ll set up a free evaluation of your entire home.</h3>
                        <ContactForm/>
                    </Content>
                </Layout>
            )
        }
    </Location>
)